import React, { useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic";
import SEM from "../../assets/pages/home/SEM.png";
import SEMcomp from "../../assets/pages/home/competitionSEM.jpg";
import "../../styles/pageStyles/home/SEMinfo.css";
// import CountDown from "./CountDown";

export default function SEMInfo() {
  return (
    <Controller>
      <Scene>
        {(progress) => (
          <section id="infoSEM">
            <div className="infoSEM p-5 container">
              <div className="title row">
                <div className="title2 col-md-6 col-12">
                  <h1 className="text-light">Shell Eco-marathon</h1>
                  <p className="pt-3 large text-light">
                    Shell Eco-marathon is one of the biggest engineering
                    competitions for students in the world. Fuel Fighter NTNU
                    competes in the Concept Class Battery Electric competition,
                    where electric concept cars compete to use the least amount
                    of power to drive on a track. Fuel Fighter NTNU also
                    competes in the Autonomous competition, where we are tested
                    in different driving challenges.
                  </p>
                </div>
                <div className="col-md-6 col-12 pt-3">
                  <img
                    className="rounded img-fluid"
                    src={SEMcomp}
                    alt="SEM image"
                  />
                </div>
              </div>
            </div>
            <div className="infoSEM p-5 container">
              <div className="row justify-content-end">
                <div className="col-md-6 d-none d-md-block">
                  <img
                    className="rounded img-fluid"
                    src={SEM}
                    alt="SEM image"
                  />
                </div>
                <div className="infoSEM col-md-6 col-12">
                  <h1
                    className="text-light"
                    style={{ fontfamily: "Roboto-Black" }}
                  >
                    Where we are competing
                  </h1>
                  <p
                    className="pt-3 large text-light"
                    style={{ fontfamily: "Roboto-light" }}
                  >
                    Fuel Fighter NTNU competes all throughout Europe in Shell
                    Eco-marathon. Where Shell Eco-marathon takes place, we go.
                    We have competed at TT Circuit Assen, Netherlands and
                    Circuit Paul Armagnac, France the last years. For the 2025
                    season we are heading to Silesia Ring, Poland.
                  </p>
                </div>
                <div className="col-12 d-md-none">
                  <img
                    className="rounded img-fluid"
                    src={SEM}
                    alt="SEM image"
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </Scene>
    </Controller>
  );
}
