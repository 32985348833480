import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import projectManager from "../../assets/pages/join/project_manager.JPG";
import assistant_project_manager from "../../assets/pages/join/assistant_project_manager.JPG";
import technicalLeader from "../../assets/pages/join/technical_leader.JPG";
import assistent_tech_lead from "../../assets/pages/join/assistent_tech_lead.JPG";
import head_of_finance from "../../assets/pages/join/head_of_finance.jpg";

import marketing_group_leader from "../../assets/pages/join/marketing_group_leader.JPG";
import autonomous_group_leader from "../../assets/pages/join/autonomous_group_leader.jpg";
import leader_design from "../../assets/pages/join/leader_design.jpg";
import groupL_electrical from "../../assets/pages/join/groupL_electrical.jpg";
import mech_leader from "../../assets/pages/join/mech_leader.jpg";
import mechanical from "../../assets/pages/join/mechanical.JPG";
import software_leader from "../../assets/pages/join/software_leader.jpg";

import GeneralPositions from "./GeneralPositions";

const LeaderPositions = () => {
  const positions = leaderPositions;
  const [selectedPosition, setSelectedPosition] = useState(positions[0]);
  const [selectedRow, setSelectedRow] = useState("Board");

  const handleSelectPosition = (position) => {
    setSelectedPosition(position);
  };

  const handleSelectRow = (event) => {
    setSelectedRow(event.target.value);
    if (event.target.value === "Board") setSelectedPosition(positions[0]);
    if (event.target.value === "Group Leader")
      setSelectedPosition(positions[1]);
  };

  const firstRowPositions = positions.slice(0, 6);
  const secondRowPositions = positions.slice(6);

  return (
    <div className="positions-wrapper">
      <Container>
        <Row>
          <Col id="centeredRow">
            <Button
              className="button-positions"
              variant={selectedRow === "Board" ? "primary" : "secondary"}
              value="Board"
              onClick={handleSelectRow}
            >
              Board
            </Button>
            <Button
              className="button-positions"
              variant={selectedRow === "Group Leader" ? "primary" : "secondary"}
              value="Group Leader"
              onClick={handleSelectRow}
            >
              Group Leaders
            </Button>
            <Button
              className="button-positions"
              variant={selectedRow === "General" ? "primary" : "secondary"}
              value="General"
              onClick={handleSelectRow}
            >
              General
            </Button>
          </Col>
        </Row>
        {selectedRow === "Board" && (
          <Row>
            {firstRowPositions.map((position) => (
              <Col
                key={position.title}
                className={`position-item ${
                  position === selectedPosition ? "selected" : ""
                }`}
                onClick={() => handleSelectPosition(position)}
              >
                {position.title}
              </Col>
            ))}
          </Row>
        )}
        {selectedRow === "Group Leader" && (
          <Row>
            {secondRowPositions.map((position) => (
              <Col
                key={position.title}
                className={`position-item ${
                  position === selectedPosition ? "selected" : ""
                }`}
                onClick={() => handleSelectPosition(position)}
              >
                {position.title}
              </Col>
            ))}
          </Row>
        )}
        {(selectedRow === "Group Leader" || selectedRow === "Board") && (
          <Row
            className="selected-position align-items-start"
            style={{ flexDirection: "row", marginTop: "2rem" }}
          >
            <h3 style={{ textAlign: "left" }}>{selectedPosition.title}</h3>
            <Col xs={12} sm={5}>
              <img
                src={selectedPosition.image}
                alt={selectedPosition.title}
                className="position-image"
              />
            </Col>
            <Col xs={12} sm={7} id="PosLeadDescription">
              <p style={{ textAlign: "left" }}>
                {selectedPosition.description}
              </p>
              <h5 style={{ textAlign: "left" }}>
                What you will learn from this position:
              </h5>
              <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
                {selectedPosition.learn
                  .split(".")
                  .slice(0, -1)
                  .map((sentence, index) => (
                    <li key={index}>{sentence.trim()}</li>
                  ))}
              </ul>
            </Col>
          </Row>
        )}
        {selectedRow === "General" && <GeneralPositions />}
      </Container>
    </div>
  );
};

export default LeaderPositions;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LEADER RECRUITMENT                                                                                                            //
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const leaderPositions = [
  {
    title: "Project Manager",
    description:
      "The project manager is responsible for the overall success of the project and leads the team to Shell Eco-marathon. Day to day, you follow up the project, ensure communication across groups and lead meetings and strategic decisions for Fuel Fighter NTNU. You will also be the face of the organization, and work with external stakeholders, including sponsors, NTNU, and other student organizations. The project manager is also responsible for delegating tasks so that the budget is kept, the technical group progresses, and that the team has a good working environment.",
    learn:
      "Gain hands-on leadership experience by managing a team and driving the project toward its goals.Develop real project management skills, including time management, budgeting, and risk management.Improve your communication abilities by collaborating with external partners such as sponsors, NTNU, and student organizations.Enhance your decision-making and conflict resolution skills by managing a diverse team and guiding strategic decisions. ",
    image: projectManager,
  },
  {
    title: "Deputy Project Manager",
    description:
      "The Deputy Project Manager works closely with the Project Manager to ensure the overall success of the project and support the team in reaching its goals for Shell Eco-marathon. You will assist in managing the project by helping to coordinate tasks across different groups, ensuring clear communication, and tracking progress on deadlines and deliverables. In the absence of the Project Manager, you will step in to lead meetings, make strategic decisions, and represent the team to external stakeholders. Additionally, you will help ensure that the budget is managed effectively and that the team maintains a positive and productive working environment. You will also play a key role in problem-solving and supporting the Project Manager in delegating tasks and ensuring the project stays on track. ",
    learn:
      "Gain hands-on experience in project management, assisting with time management, budgeting, and tracking progress on deliverables.Develop leadership skills by stepping into a management role when needed and helping to guide teams towards achieving key milestones.Improve your organizational and communication skills by coordinating tasks across multiple groups and ensuring smooth internal and external communications.Enhance your decision-making skills by assisting in strategic planning and problem-solving within a fast-paced, collaborative environment. ",
    image: assistant_project_manager,
  },
  {
    title: "Technical Leader",
    description:
      "The technical leader is responsible for the technical groups of Fuel Fighter NTNU, ensuring that we have a rules-compliant and working car at competition. Day to day, you coordinate with group leaders, arrange testing, arrange peer reviews, and follow up the design, production, and testing work. You are the closest leader to the technical group leaders and will have responsibility for following up the leaders and members of each group together with the assistant technical leader. Additionally, you will work as the system engineer for the team, making sure all the subsystems function seamlessly and integrate effectively with one another. ",
    image: technicalLeader,
    learn:
      "Gain hands-on leadership experience in a real-world setting.Develop a deep understanding of engineering principles by working across multiple disciplines, from design to production and testing.Get insight into the world of automotive engineering, with a focus on energy efficiency and performance optimization.Enhance your problem-solving and leader skills by overseeing the integration of complex subsystems and ensuring smooth teamwork across all stages of development. ",
  },
  {
    title: "Assistant Technical Leader",
    image: assistent_tech_lead,
    description:
      "The assistant technical leader is the right hand of the technical leader of Fuel Fighter NTNU, in the task of ensuring that we have a rules-compliant and working car at competition. Day to day, you coordinate with group leaders, arrange production, keep track of progress, and follow up the design, production, and testing work along with the technical leader. You will have a position on the board and participate in strategic decision making and have a large influence on our development and design. Additionally, you will work as the system engineer for the team, making sure all the subsystems function seamlessly and integrate effectively with one another. ",
    learn:
      "Gain hands-on leadership experience in a real-world setting.Develop a deep understanding of engineering principles by working across multiple disciplines, from design to production and testing.Get insight into the world of automotive engineering, with a focus on energy efficiency and performance optimization.Enhance your problem-solving and leader skills by overseeing the integration of complex subsystems and ensuring smooth teamwork across all stages of development. ",
  },
  {
    title: "Marketing Leader",
    image: marketing_group_leader,
    description:
      "The marketing leader is responsible for executing the marketing strategy for Fuel Fighter NTNU. You will collaborate with the team to raise awareness, promote achievements, and engage with sponsors and partners. Your role includes creating content for social media, managing communication with stakeholders, handling public relations, and arranging the recruitment period. You will develop promotional materials and ensure consistent brand messaging across platforms. As marketing leader, you’ll be key in positioning the team, attracting supporters, and driving engagement throughout the competition. Your creativity, strategy, and communication skills are vital in building a strong, recognizable brand for the team. ",
    learn:
      "Gain hands-on leadership experience in a real-world setting.Develop expertise in creating and executing marketing campaigns across multiple platforms, including social media, content creation, and public relations.Improve your communication, negotiation, and networking skills by working with a variety of people, from team members to industry professionals.Strengthen your strategic thinking, creativity, and problem-solving abilities while building a strong, recognizable brand for a high-profile project. ",
  },
  {
    title: "Finance Leader ",
    image: head_of_finance,
    description:
      "The finance leader manages the financial aspects of Fuel Fighter NTNU, ensuring the team operates within budget and secures necessary funding. You will develop and oversee the team’s budget, track expenses, and allocate resources efficiently. Your role includes identifying funding opportunities, managing sponsorships, and maintaining financial records. You will collaborate with other team leaders to prioritize spending and ensure financial decisions support the team’s success. As the finance leader, you’ll be key in ensuring financial sustainability and securing resources for development. ",
    learn:
      "Gain hands-on experience in financial management, including budgeting, forecasting, and financial planning for a high-profile project.Develop skills in securing funding and managing sponsorships, while building relationships with external stakeholders and partners.Enhance your strategic thinking by balancing financial priorities with operational needs and making key decisions for project success. ",
  },
  {
    title: "Production Leader ",
    image: head_of_finance,
    description:
      "The production leader is responsible for overseeing the manufacturing process of the Fuel Fighter NTNU car. You will coordinate the production schedule, manage resources, and ensure parts are built to specifications and on time. Your role includes collaborating with the technical groups to ensure final products meet required standards, handling quality control, and solving production-related issues. You will also be responsible for identifying and securing production locations, such as CNC machining or other facilities, and planning these processes. As the production leader, you will play a crucial role in ensuring the timely and efficient completion of the car, while maintaining high quality standards. ",
    learn:
      "Gain hands-on experience in managing the production process, from planning to execution in a high-profile project.Learn about advanced manufacturing techniques, including CNC machining, 3D printing, and other production methods.Strengthen problem-solving skills by addressing production challenges and quality control issues. ",
  },
  {
    title: "Mechanical Leader ",
    image: mech_leader,
    description:
      "The Mechanical Leader is responsible for overseeing the mechanical aspects of the car, including the design, development, and manufacturing of parts such as the suspension, drivetrain, and structural elements. This role also involves cross-coordination with the other technical groups to ensure reliability and integration of mechanical systems with the other subsystems. You will ensure all components meet performance, safety, and competition standards while working closely with electrical, autonomous, and other groups. Additionally, the mechanical leader manages deadlines, ensuring the group stays on schedule and troubleshooting issues during design, production, and testing. ",
    learn:
      "Improve your leadership abilities by managing a team, coordinating tasks, and ensuring deadlines are met across multiple technical groups.Gain an overview of the mechanical parts of a car, from suspencion to drivetrain, and how they contribute to overall performance.Deepen your understanding of vehicle performance, safety standards, and competition regulations, and how to optimize mechanical components to meet them. ",
  },
  {
    title: "Electrical Leader ",
    image: groupL_electrical,
    description:
      "The Electrical Leader is responsible for overseeing the design, development, and integration of all electrical systems in the vehicle. You will ensure that all electrical components work together efficiently and are fully prepared for competition. With a strong focus on optimizing energy usage and performance, you will lead the team in developing systems such as power distribution, battery management, and motorcontrollers. You will also ensure that the electrical systems are well integrated with other subsystems, such as mechanical and autonomous. Additionally, you will be responsible for managing the group's deadlines, making sure all tasks are completed on time, and troubleshooting any electrical issues during design, production, and testing. ",
    learn:
      "Strengthen your leadership and management skills by guiding a team to meet deadlines, coordinate tasks, and troubleshoot electrical challenges throughout design, testing, and production.Deepen your knowledge of competition rules and regulations, ensuring all electrical systems meet safety and performance standards.Learn how to optimize electrical efficiency, focusing on energy management and performance in a competitive environment. ",
  },
  {
    title: "Chassis Leader ",
    image: mechanical,
    description:
      "The Chassis Leader is responsible for leading the design process of the chassis and driver interface for the car. You will ensure that the chassis provides adequate space for mechanical and electrical components while maintaining a compact and efficient structure. A key focus will be optimizing the car’s aerodynamics and minimizing weight, ensuring that the car is as lightweight and efficient as possible without sacrificing performance. You will work closely with other technical leaders to integrate all subsystems into the chassis, balancing functionality, strength, and safety. Additionally, you will oversee testing and adjustments to ensure the chassis meets competition standards and performs effectively on the track. ",
    learn:
      "Strengthen your leadership and management skills by guiding a team to meet deadlines, coordinate tasks, and troubleshoot electrical challenges throughout design, testing, and production.Gain hands-on experience in chassis and aeroframe design, with a focus on aerodynamics, weight optimization, and creating a driver-friendly interface.Learn how to use CAD software and other design tools to develop and refine the chassis, ensuring it meets both performance and safety standards.",
  },
];
